<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import { useToast } from 'primevue/usetoast';
import axios from '../Axios.js';

const route = useRoute();
const toast = useToast();
const signaturePad = ref(null);
const loading = ref(true);
const requestData = ref(null);
const error = ref(null);
const isExpired = ref(false);
const isCompleted = ref(false);
const pdfUrl = ref(null);
const submitting = ref(false);

const schema = yup.object().shape({
    agreement: yup.boolean().oneOf([true], 'You must agree to the terms')
});

const { handleSubmit, errors, defineInputBinds, setFieldValue, values } = useForm({
    validationSchema: schema,
    initialValues: {
        agreement: false
    }
});

const agreement = defineInputBinds('agreement');

onMounted(async () => {
    try {
        const token = route.params.token;
        const response = await axios.get(`/signature-request/${token}`);

        if (response.data.success) {
            requestData.value = response.data.data;
            pdfUrl.value = response.data.data.pdfUrl;
        } else {
            // Handle error response
            error.value = response.data.message;

            if (response.data.status === 'expired') {
                isExpired.value = true;
            } else if (response.data.status === 'completed') {
                isCompleted.value = true;
            }
        }
    } catch (err) {
        console.error('Error loading signature request:', err);
        error.value = 'Unable to load the signature request. Please try again later.';
    } finally {
        loading.value = false;
    }
});

const signatureStyles = {
    border: '#c3c3c3 2px solid',
    padding: '1rem',
    backgroundColor: '#e4e4e4'
};

const clearSig = () => {
    if (signaturePad.value) {
        signaturePad.value.clearSignature();
    }
};

const checkForErrors = async () => {
    if (!signaturePad.value) {
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'Signature pad not available',
            life: 5000
        });
        return true;
    }

    const { isEmpty } = signaturePad.value.saveSignature();
    if (isEmpty) {
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'You must sign before submitting',
            life: 5000
        });
        return true;
    }

    // Try to submit the form
    await submit();

    // Check form validation errors
    if (Object.keys(errors.value).length > 0) {
        const error = Object.values(errors.value)[0];
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: error,
            life: 5000
        });
        return true;
    }

    return false;
};

const submit = handleSubmit(async (values) => {
    const { isEmpty, data } = signaturePad.value.saveSignature();
    if (isEmpty) {
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'You must sign before submitting',
            life: 5000
        });
        return;
    }

    submitting.value = true;

    try {
        const token = route.params.token;
        const response = await axios.post(`/signature-request/submit`, {
            token: token,
            signature: data,
            agreement: values.agreement
        });

        if (response.data.success) {
            toast.add({
                summary: 'Success',
                severity: 'success',
                detail: 'Service agreement signed successfully',
                life: 5000
            });

            // Mark as completed
            isCompleted.value = true;
        } else {
            toast.add({
                summary: 'Error',
                severity: 'error',
                detail: response.data.message,
                life: 5000
            });
        }
    } catch (err) {
        console.error('Error submitting signature:', err);
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'Failed to submit signature. Please try again.',
            life: 5000
        });
    } finally {
        submitting.value = false;
    }
});
</script>

<template>
    <div class="card flex justify-center pt-3">
        <div class="w-11/12 pb-8">
            <h2 class="text-center text-2xl mb-4">Service Agreement</h2>

            <BlockUI :blocked="loading" fullScreen />
            <div v-if="loading" class="fixed top-0 left-0 w-full h-full">
                <div class="flex justify-center items-center h-full">
                    <ProgressSpinner />
                </div>
            </div>

            <!-- Error States -->
            <div v-if="error && !loading" class="rounded-2xl bg-red-100 text-red-800 font-medium p-8 mb-4">
                <div class="text-xl mb-4">{{ error }}</div>

                <div v-if="isExpired" class="text-lg">
                    This signature request has expired. Please contact Feazel Roofing for a new signature request.
                </div>

                <div v-if="isCompleted" class="text-lg">
                    This service agreement has already been signed. Thank you!
                </div>
            </div>

            <!-- Success State (after submission) -->
            <div v-if="isCompleted && !loading" class="rounded-2xl bg-green-100 text-green-800 font-medium p-8 mb-4">
                <div class="text-xl mb-4">Thank you for your signature!</div>
                <p class="text-lg">
                    Your signed service agreement has been processed successfully.
                </p>
            </div>

            <!-- Main Content -->
            <div v-if="!error && !isCompleted && !loading">
                <!-- Customer Info -->
                <div class="rounded-2xl bg-surface-100 font-medium p-8 mb-4">
                    <div class="text-2xl text-orange-500 border-b-2 pb-4 mb-6">Service Agreement Details</div>

                    <div class="grid grid-cols-2 gap-4">
                        <div class="flex flex-col gap-2">
                            <label class="font-medium">Case Number:</label>
                            <div>{{ requestData.caseNumber }}</div>
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="font-medium">Document:</label>
                            <div>{{ requestData.pdfName }}</div>
                        </div>
                    </div>
                </div>

                <!-- PDF Preview -->
                <div class="rounded-2xl bg-surface-100 font-medium p-8 mb-4">
                    <div class="text-2xl text-orange-500 border-b-2 pb-4 mb-6">Service Agreement Document</div>

                    <div class="mb-4">
                        Please review the service agreement document carefully before signing.
                    </div>

                    <div v-if="pdfUrl" class="w-full border-1 border-300 rounded-md">
                        <iframe :src="pdfUrl" width="100%" height="600" style="border:none;"></iframe>
                    </div>
                </div>

                <!-- Signature Section -->
                <div class="rounded-2xl bg-surface-100 font-medium p-8 mb-4">
                    <div class="text-2xl text-orange-500 border-b-2 pb-4 mb-6">Sign Agreement</div>

                    <div class="flex flex-col">
                        <div class="flex items-center my-5">
                            <Checkbox inputId="agreement"
                                      name="agreement"
                                      binary
                                      :invalid="!!errors.agreement"
                                      :value="values.agreement"
                                      :modelValue="values.agreement"
                                      @update:modelValue="setFieldValue('agreement', $event)" />
                            <label for="agreement" class="ml-2">
                                I have read and agree to the terms and conditions in the service agreement
                            </label>
                        </div>
                        <small v-if="errors.agreement" class="text-red-500 mb-4">{{ errors.agreement }}</small>

                        <div class="mt-10 flex flex-col gap-2">
                            <div class="text-lg">Signature *</div>
                            <div class="w-full">
                                <VueSignaturePad ref="signaturePad" :customStyle="signatureStyles" />
                            </div>
                            <Button class="w-20" link label="Clear" @click="clearSig" />
                        </div>

                        <div class="pt-6 flex justify-end">
                            <Button label="Sign & Submit"
                                    :loading="submitting"
                                    :disabled="!values.agreement"
                                    @click="checkForErrors" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Toast position="top-center" />
</template>