<script setup>
import { ref } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import Select from 'primevue/select';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import axios from '../Axios.js';
import router from '../router/index.js';

const toast = useToast();
const loading = ref(false);

const schema = yup.object().shape({
    clientEmail: yup.string().email('Invalid email').required('This field is required'),
    requestingParty: yup.string().required('This field is required'),
    associatedBranch: yup.string().required('This field is required'),
    dateOfRequest: yup.date().required('This field is required'),
    contactName: yup.string().required('This field is required'),
    contactPhone: yup.string().required('This field is required'),
    contactEmail: yup.string().email('Invalid email').required('This field is required'),
    homeownerAddress: yup.string().required('This field is required'),
    companyCamLink: yup.string().required('This field is required'),
    measurementPackage: yup.string().required('This field is required'),
    measurementReportLink: yup.string(),
    selectedTrades: yup.array().min(1, 'At least one trade must be selected'),
    otherTradeSpecify: yup.string().when('selectedTrades', {
        is: (value) => value && value.includes('Other'),
        then: () => yup.string().required('Please specify other trade'),
        otherwise: () => yup.string(),
    }),
    tradeNotes: yup.string()
});

const { handleSubmit, values, errors, setFieldValue, resetForm } = useForm({
    validationSchema: schema,
    initialValues: {
        clientEmail: '',
        requestingParty: '',
        associatedBranch: '',
        dateOfRequest: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        homeownerAddress: '',
        companyCamLink: '',
        measurementPackage: '',
        measurementReportLink: '',
        selectedTrades: [],
        otherTradeSpecify: '',
        tradeNotes: ''
    }
});

const trades = [
    { label: 'Siding', value: 'Siding' },
    { label: 'Metal Roofing', value: 'Metal Roofing' },
    { label: 'Copper', value: 'Copper' },
    { label: 'Gutters', value: 'Gutters' },
    { label: 'Paint', value: 'Paint' },
    { label: 'Other', value: 'Other' }
];

const measurementPackages = [
    { label: 'GAF QM', value: 'GAF QM' },
    { label: 'Other', value: 'Other' }
];

const locations = ['Columbus Production', 'Greenville Production'];

const submit = handleSubmit(async (values) => {
    loading.value = true;

    const formDataToSubmit = new FormData();

    for (const [key, value] of Object.entries(values)) {
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                formDataToSubmit.append(`${key}[${index}]`, item);
            });
        } else if (typeof value === 'boolean') {
            formDataToSubmit.append(key, value ? '1' : '0');
        } else {
            formDataToSubmit.append(key, value);
        }
    }

    try {
        const response = await axios.post('/save-customization', formDataToSubmit);
        const data = await response.data;

        loading.value = false;
        toast.add({
            summary: 'Success',
            severity: 'success',
            detail: 'Successfully sent invitation',
            life: 5000
        });

        resetForm();

        const parentDomain = import.meta.env.VITE_PARENT_DOMAIN;
        const emails = [
            values.clientEmail,
            import.meta.env.VITE_COPY_INVITE_EMAILS
        ];

        await axios.post(`/send-email`, {
            emailAddresses: emails.join(','),
            emailSubject: `Sub-Bid Request Form`,
            emailBody: `
You have been invited to complete the Sub-Bid Request Form. Please click the link below to complete the form.
<br>
<a href="${parentDomain}/sub-bid/?id=${data.id}">${parentDomain}/sub-bid/?id=${data.id}</a>
`,
            html: true
        });
    } catch (error) {
        loading.value = false;
        console.error('Error:', error);
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'Failed to send invitation',
            life: 5000
        });
    }
});
</script>

<template>
    <div class="card flex justify-center">
        <div class="flex gap-2 flex-col mt-5 w-5/12 lg:w-8/12">
            <div class="bg-primary-200 font-medium p-8" style="margin-bottom: -10px">
                <h1 class="text-3xl">Sub-Bid Form Request</h1>
                <h2 class="text-lg">For internal use only!</h2>
                <p class="mt-10">
                    Complete this form to provide specific details required for the contractor to finish the process.
                </p>
                <p>
                    Once submitted, a unique link will be generated and emailed to the contractor.
                    This link allows the contractor to enter their information.
                </p>
            </div>

            <!-- Main Form Section -->
            <div class="rounded-b-2xl bg-surface-100 font-medium p-8">
                <div class="flex flex-wrap gap-5">
                    <!-- Basic Information -->
                    <div class="flex flex-col gap-2 w-full">
                        <h3 class="text-xl font-bold mb-4">Basic Information</h3>
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div class="flex flex-col gap-2">
                                <label>Email</label>
                                <InputText
                                    :modelValue="values.clientEmail"
                                    @update:modelValue="setFieldValue('clientEmail', $event)"
                                    :class="{ 'p-invalid': errors.clientEmail }"
                                />
                                <small class="text-red-500">{{ errors.clientEmail }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>Feazel Requesting Party</label>
                                <Select
                                    :options="locations"
                                    placeholder="Select One"
                                    :modelValue="values.requestingParty"
                                    @change="setFieldValue('requestingParty', $event.value)"
                                    :class="{ 'p-invalid': errors.requestingParty }"
                                />
                                <small class="text-red-500">{{ errors.requestingParty }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>Associated Branch</label>
                                <Select
                                    :options="locations"
                                    placeholder="Select One"
                                    :modelValue="values.associatedBranch"
                                    @change="setFieldValue('associatedBranch', $event.value)"
                                    :class="{ 'p-invalid': errors.associatedBranch }"
                                />
                                <small class="text-red-500">{{ errors.associatedBranch }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>Date of Request</label>
                                <Calendar
                                    :modelValue="values.dateOfRequest"
                                    @update:modelValue="setFieldValue('dateOfRequest', $event)"
                                    :class="{ 'p-invalid': errors.dateOfRequest }"
                                />
                                <small class="text-red-500">{{ errors.dateOfRequest }}</small>
                            </div>
                        </div>
                    </div>

                    <!-- Contact Information -->
                    <div class="flex flex-col gap-2 w-full">
                        <h3 class="text-xl font-bold mb-4">Contact Information for Sub-Bid Team</h3>
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div class="flex flex-col gap-2">
                                <label>Name</label>
                                <InputText
                                    :modelValue="values.contactName"
                                    @update:modelValue="setFieldValue('contactName', $event)"
                                    :class="{ 'p-invalid': errors.contactName }"
                                />
                                <small class="text-red-500">{{ errors.contactName }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>Phone</label>
                                <InputText
                                    :modelValue="values.contactPhone"
                                    @update:modelValue="setFieldValue('contactPhone', $event)"
                                    :class="{ 'p-invalid': errors.contactPhone }"
                                />
                                <small class="text-red-500">{{ errors.contactPhone }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>Email</label>
                                <InputText
                                    :modelValue="values.contactEmail"
                                    @update:modelValue="setFieldValue('contactEmail', $event)"
                                    :class="{ 'p-invalid': errors.contactEmail }"
                                />
                                <small class="text-red-500">{{ errors.contactEmail }}</small>
                            </div>
                        </div>
                    </div>

                    <!-- Property Information -->
                    <div class="flex flex-col gap-2 w-full">
                        <h3 class="text-xl font-bold mb-4">Property Information</h3>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div class="flex flex-col gap-2">
                                <label>Homeowner Address</label>
                                <InputText
                                    :modelValue="values.homeownerAddress"
                                    @update:modelValue="setFieldValue('homeownerAddress', $event)"
                                    :class="{ 'p-invalid': errors.homeownerAddress }"
                                />
                                <small class="text-red-500">{{ errors.homeownerAddress }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>CompanyCam Link</label>
                                <InputText
                                    :modelValue="values.companyCamLink"
                                    @update:modelValue="setFieldValue('companyCamLink', $event)"
                                    :class="{ 'p-invalid': errors.companyCamLink }"
                                />
                                <small class="text-red-500">{{ errors.companyCamLink }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>Measurement Package</label>
                                <Select
                                    :options="measurementPackages"
                                    placeholder="Select Package"
                                    :modelValue="values.measurementPackage"
                                    @change="setFieldValue('measurementPackage', $event.value)"
                                    :class="{ 'p-invalid': errors.measurementPackage }"
                                />
                                <small class="text-red-500">{{ errors.measurementPackage }}</small>
                            </div>
                            <div class="flex flex-col gap-2">
                                <label>Measurement Report Link (if applicable)</label>
                                <InputText
                                    :modelValue="values.measurementReportLink"
                                    @update:modelValue="setFieldValue('measurementReportLink', $event)"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Trade Requirements -->
                    <div class="flex flex-col gap-2 w-full">
                        <h3 class="text-xl font-bold mb-4">Trade Required</h3>
                        <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                            <div v-for="trade in trades" :key="trade.value" class="flex items-center gap-2">
                                <Checkbox
                                    :value="trade.value"
                                    :modelValue="values.selectedTrades"
                                    @update:modelValue="setFieldValue('selectedTrades', $event)"
                                    :id="trade.value"
                                />
                                <label :for="trade.value">{{ trade.label }}</label>
                            </div>
                        </div>
                        <small class="text-red-500">{{ errors.selectedTrades }}</small>

                        <div v-if="values.selectedTrades.includes('Other')" class="flex flex-col gap-2 mt-4">
                            <label>Specify Other Trade</label>
                            <InputText
                                :modelValue="values.otherTradeSpecify"
                                @update:modelValue="setFieldValue('otherTradeSpecify', $event)"
                                :class="{ 'p-invalid': errors.otherTradeSpecify }"
                            />
                            <small class="text-red-500">{{ errors.otherTradeSpecify }}</small>
                        </div>
                    </div>

                    <!-- Notes -->
                    <div class="flex flex-col gap-2 w-full">
                        <h3 class="text-xl font-bold mb-4">Notes Specific to Trade Request</h3>
                        <Textarea
                            :modelValue="values.tradeNotes"
                            @update:modelValue="setFieldValue('tradeNotes', $event)"
                            rows="4"
                            class="w-full"
                            :class="{ 'p-invalid': errors.tradeNotes }"
                        />
                        <small class="text-red-500">{{ errors.tradeNotes }}</small>
                    </div>
                </div>
            </div>

            <!-- Submit Button -->
            <div class="mt-5 mb-10 flex justify-end">
                <Button @click="submit" label="Submit" />
            </div>
        </div>
    </div>

    <Toast position="top-center" />
    <BlockUI :blocked="loading" fullScreen />
    <div v-if="loading" class="fixed top-0 left-0 w-full h-full">
        <div class="flex justify-center items-center h-full">
            <ProgressSpinner />
        </div>
    </div>
</template>